<template>
  <div class="home">
    <h3>Tibura Amazon Authorization</h3>
    <p>Please contact tibura administrator to authorize the seller central application</p>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'HomeView'
});
</script>
